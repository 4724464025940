import { FC, useEffect, useState } from 'react'
import { Tabs, Text } from 'src/ui/components'
import styles from './Advantages.module.scss'
import { UserLevelTab } from './UserLevelTab'
import { UserLevel, UserLevelV2 } from 'src/core/User/domain/User'
import {
  uniqueBenefits,
  essentialBenefits,
  specialBenefits,
} from './BenefitType'

type Tab = {
  title: string
  container: JSX.Element
}

type Props = {
  userLevel: UserLevelV2 | UserLevel
  title: string
}

export const Advantages: FC<Props> = ({ userLevel, title }) => {
  const [tabs, setTabs] = useState([] as Tab[])

  useEffect(() => {
    setTabs(generateTabs(userLevel))
  }, [userLevel])

  return (
    <div className={styles.container}>
      <Text fontStyle="2xl-700" as="h2" centered>
        {title}
      </Text>
      {userLevel === UserLevelV2.Unique ? (
        <UserLevelTab type="unique" benefits={uniqueBenefits} />
      ) : (
        <Tabs tabs={tabs} className={styles.tabs} />
      )}
    </div>
  )
}

function generateTabs(userLevel: UserLevelV2 | UserLevel) {
  const essentialTab = {
    title: 'Essential',
    container: <UserLevelTab type="essential" benefits={essentialBenefits} />,
  }
  const specialTab = {
    title: 'Special',
    container: <UserLevelTab type="special" benefits={specialBenefits} />,
  }
  const uniqueTab = {
    title: 'Unique',
    container: <UserLevelTab type="unique" benefits={uniqueBenefits} />,
  }

  if (
    [
      UserLevelV2.Essential,
      UserLevelV2.NotFound,
      UserLevel.NotFound,
      UserLevel.Start,
    ].includes(userLevel)
  ) {
    return [essentialTab, specialTab, uniqueTab]
  }

  if (userLevel === UserLevelV2.Special || userLevel === UserLevel.Intense) {
    return [specialTab, uniqueTab]
  }

  return [uniqueTab]
}
