import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  AvailabilityMealplan,
  AvailabilityRate,
} from 'src/core/Availability/domain/Availability.model'
import { RatePolicies } from 'src/ui/views/AvailableRooms/Room/RateSelector/RateSelector.model'
import { container } from 'src/core/Shared/_di'
import { CustomEvent } from 'src/core/Shared/infrastructure/eventsManager'

const defaultState = {
  selectedGlobalMealplan: undefined,
  setSelectedGlobalMealplan: () => {},
  selectedGlobalRate: undefined,
  setSelectedGlobalRate: () => {},
  selectedGlobalRatePolicies: undefined,
  setSelectedGlobalRatePolicies: () => {},
}

export const RateSelectorContext = createContext<{
  selectedGlobalMealplan: AvailabilityMealplan | undefined
  setSelectedGlobalMealplan: (mealplan: AvailabilityMealplan) => void
  selectedGlobalRate: AvailabilityRate | undefined
  setSelectedGlobalRate: (rate: AvailabilityRate) => void
  selectedGlobalRatePolicies: RatePolicies | undefined
  setSelectedGlobalRatePolicies: (ratePolicies: RatePolicies) => void
}>(defaultState)

interface Props {
  children: ReactNode
}

export const RateSelectorProvider: FC<Props> = ({ children }) => {
  const [selectedGlobalMealplan, setSelectedGlobalMealplan] = useState<
    AvailabilityMealplan | undefined
  >()
  const [selectedGlobalRate, setSelectedGlobalRate] = useState<
    AvailabilityRate | undefined
  >()
  const [selectedGlobalRatePolicies, setSelectedGlobalRatePolicies] = useState<
    RatePolicies | undefined
  >()

  const resetRateSelector = useCallback(() => {
    setSelectedGlobalMealplan(undefined)
    setSelectedGlobalRate(undefined)
    setSelectedGlobalRatePolicies(undefined)
  }, [])

  useEffect(() => {
    const eventsManager = container.resolve('eventsManager')
    const unsubscribe = eventsManager.on(
      CustomEvent.RESET_PRE_RESERVE,
      resetRateSelector,
    )

    return () => {
      resetRateSelector()
      unsubscribe()
    }
  }, [resetRateSelector])

  return (
    <RateSelectorContext.Provider
      value={{
        selectedGlobalMealplan,
        setSelectedGlobalMealplan,
        selectedGlobalRate,
        setSelectedGlobalRate,
        selectedGlobalRatePolicies,
        setSelectedGlobalRatePolicies,
      }}
    >
      {children}
    </RateSelectorContext.Provider>
  )
}

export const useRateSelector = () => useContext(RateSelectorContext)
