import { FC } from 'react'

export const BedIcon: FC = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.77778 11.1138H20.2222C21.2041 11.1138 22 11.9076 22 12.8868V17.0978H2V12.8868C2 11.9076 2.79594 11.1138 3.77778 11.1138Z"
      stroke="#384044"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.9148 10.8V5.07278C19.9148 4.36987 19.3178 3.80005 18.5815 3.80005H5.24813C4.51175 3.80005 3.91479 4.36987 3.91479 5.07278V10.8"
      stroke="#384044"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.7446 10.8V8.60005C15.7446 8.15822 15.3467 7.80005 14.8557 7.80005H8.63352C8.1426 7.80005 7.74463 8.15822 7.74463 8.60005V10.8"
      stroke="#384044"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="2.25"
      y1="17.55"
      x2="2.25"
      y2="20.05"
      stroke="#384044"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="22.25"
      y1="17.55"
      x2="22.25"
      y2="20.05"
      stroke="#384044"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </>
)

export default BedIcon
