import { FC } from 'react'

interface Props {
  color: string
}

export const PencilIcon: FC<Props> = ({ color }) => (
  <path
    d="M19.55 6.095L16.9 3.435C16.32 2.855 15.32 2.855 14.74 3.435L3.15 15.035C3.06 15.125 3 15.255 3 15.385V19.495C3 19.775 3.22 19.995 3.5 19.995H7.61C7.74 19.995 7.87 19.945 7.96 19.845L19.55 8.255C19.84 7.965 20 7.585 20 7.175C20 6.765 19.84 6.385 19.55 6.095ZM7.4 18.995H4V15.595L11.71 7.885L15.11 11.285L7.4 18.995ZM18.85 7.545L15.82 10.575L12.42 7.175L15.45 4.145C15.65 3.945 16 3.945 16.2 4.145L18.85 6.805C18.95 6.905 19 7.035 19 7.175C19 7.315 18.95 7.445 18.85 7.545Z"
    fill={color}
  />
)

export default PencilIcon
