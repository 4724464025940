import BedIcon from 'src/ui/components/atoms/Icon/_icons/general/BedIcon'
import styles from './FastBookingDesktop.module.scss'
import { Skeleton } from 'src/ui/components'
import CalendarIcon from 'src/ui/components/atoms/Icon/_icons/general/CalendarIcon'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { Flex } from 'src/ui/styles/objects/Flex'

export const FastBookingDesktopSkeleton = () => {
  return (
    <div className={styles.container}>
      <Skeleton height={216} width={286} />

      <div className={styles.dataWrapper}>
        <Flex justifyContent="space-between">
          <Skeleton height={34} width={300} />
          <Skeleton height={34} width={200} />
        </Flex>
        <Flex alignItems="stretch">
          <Flex direction="column" className="w-5/12">
            <Skeleton height={23} width={250} className="mb-m mt-xs" />
            <Skeleton height={23} width={250} className="mb-m" />
            <Skeleton height={23} width={100} />
          </Flex>

          <div className={styles.verticalDivider}></div>

          <Flex direction="column" className="w-7/12">
            <div className={styles.heroLineDataWrapper}>
              <div className={styles.heroLineData}>
                <Icon size="l" icon={CalendarIcon} className="mr-xs" />

                <span className={styles.heroLineEllipsisText}>
                  <Skeleton height={23} width={250} />
                </span>
              </div>
              <Skeleton height={23} width={70} />
            </div>

            <div className={styles.heroLineDataWrapper}>
              <div className={styles.heroLineData}>
                <Icon size="l" icon={BedIcon} className="mr-xs" />
                <Skeleton height={23} width={250} />
              </div>
              <Skeleton height={23} width={70} />
            </div>

            <Flex
              gap="xs"
              alignItems="center"
              className="w-full touch-security-zone-always"
            >
              <Skeleton height={23} width={200} />
            </Flex>
          </Flex>
        </Flex>
      </div>
    </div>
  )
}
