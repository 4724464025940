import React from 'react'
import styles from './MyBarceloMiniBanner.module.scss'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Text } from 'src/ui/components'
import { useUser } from 'src/ui/contexts/UserContext'
import { UserLevelV2 } from 'src/core/User/domain/User'
import { classNames } from 'src/ui/utils/classnames'
import { useTrans } from 'src/ui/hooks/useTrans'
import { MyBarceloBenefitsLogoHorizontal } from 'src/ui/views/_components/MyBarceloBenefitsLogo'

export const MyBarceloMiniBanner = () => {
  const { user } = useUser()

  const { trans } = useTrans(['new-reservation'])

  const isUniqueUser = user?.level === UserLevelV2.Unique

  return (
    <section
      className={classNames(
        styles.container,
        styles[user?.level ?? UserLevelV2.NotFound],
      )}
    >
      <Flex gap="xxs" direction="column" className={styles.content}>
        <MyBarceloBenefitsLogoHorizontal
          userLevel={user?.level as UserLevelV2}
          size="x-large"
          variant={isUniqueUser ? 'light' : 'dark'}
        />
        <Text fontStyle="m-300" color={isUniqueUser ? 'light' : 'dark'}>
          {trans(
            'new-reservation:available-rooms_my-barcelo-small-mini-banner_subtitle_public-revamp',
          )}
        </Text>
      </Flex>
    </section>
  )
}
