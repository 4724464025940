import {
  isDefined,
  isEmpty,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { getGuestsResumeTitle } from 'src/ui/i18n/getGuestsResumeTitle'
import { useTrans } from 'src/ui/hooks/useTrans'
import { useAvailability } from 'src/ui/contexts/AvailabilityContext'
import { useCoupons } from 'src/ui/contexts/CouponsContext'
import { AvailabilityError } from 'src/core/Availability/domain/AvailabilityError'
import { datesManager } from 'src/core/Shared/infrastructure/datesManager'
import { useHotel } from 'src/ui/hooks/queries/useHotel'

export const useHotelFastBooking = () => {
  const { trans } = useTrans(['new-reservation'])
  const {
    availabilityCriteria,
    error,
    isValidating: isAvailabilityValidating,
  } = useAvailability()
  const { numberOfCoupons, availabilityCoupon } = useCoupons()
  const hotel = useHotel()

  if (isUndefined(hotel) || isUndefined(availabilityCriteria)) {
    return {
      hotel: undefined,
      availabilityCriteria: undefined,
      getCouponLinkText: () => undefined,
    }
  }

  const availabilityError =
    isDefined(error) && !isAvailabilityValidating ? error : undefined

  const hasHotelAvailabilityNotFoundError =
    isDefined(availabilityError) &&
    AvailabilityError.isHotelAvailabilityNotFoundError(availabilityError)

  const nights = trans('common:cart_nights', {
    nights: datesManager.calculateNights(
      availabilityCriteria.checkIn,
      availabilityCriteria.checkOut,
    ),
  })

  const guestResume = getGuestsResumeTitle({
    adultsList: availabilityCriteria.adults,
    childrenList: availabilityCriteria.children,
    trans,
    showRooms: true,
  })

  const getApplyingCouponUnlessPromotional = () => {
    if (availabilityCoupon?.isPromotional()) {
      return availabilityCoupon
    }

    if (availabilityCoupon?.applies) {
      return availabilityCoupon
    }

    return undefined
  }

  const isCouponApplied = getApplyingCouponUnlessPromotional()?.applies ?? false

  const getCouponLinkText = () => {
    const coupon = getApplyingCouponUnlessPromotional()
    if (!coupon?.applies || isUndefined(numberOfCoupons)) {
      return trans('new-reservation:fastbooking_add-discount')
    }

    return coupon.value
  }

  const hasDestination = !isEmpty(hotel.destination)

  return {
    hotel,
    availabilityCriteria,
    hasDestination,
    hasHotelAvailabilityNotFoundError,
    nights,
    isCouponApplied,
    getCouponLinkText,
    guestResume,
  }
}
