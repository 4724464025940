import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import styles from './HotelPromotionBanner.module.scss'
import BComBlackIcon from 'src/ui/components/atoms/Icon/_icons/general/BComBlackIcon'
import { useModal } from 'src/ui/hooks/useModal'
import { HotelPromotionModal } from './HotelPromotionModal'
import { useHotelPromotions } from 'src/ui/hooks/queries/useHotelPromotions'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Text } from 'src/ui/components/atoms/Text'
import { useTrans } from 'src/ui/hooks/useTrans'

export const HotelPromotionBanner = () => {
  const { trans } = useTrans(['new-reservation'])
  const {
    showModal: showHotelPromotionModal,
    hideModal: hideHotelPromotionModal,
  } = useModal(HotelPromotionModal)

  const { hotelPromotions, isLoadingPromotions } = useHotelPromotions()
  const hasHotelPromotions =
    isDefined(hotelPromotions) && hotelPromotions.length > 0

  const handleShowHotelPromotionModal = () => {
    showHotelPromotionModal({
      hotelPromotions,
      onCloseModal: hideHotelPromotionModal,
    })
  }

  if (isLoadingPromotions || !hasHotelPromotions) {
    return null
  }

  return (
    <button
      className={styles.container}
      onClick={handleShowHotelPromotionModal}
    >
      <Icon icon={BComBlackIcon} size="l" />
      <Text fontStyle="s-500" className="ml-xs">
        {trans('availability_hotel-promotions_title')}
      </Text>
    </button>
  )
}
