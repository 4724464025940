import React, { FC, useEffect, useState, useMemo } from 'react'
import {
  AvailabilityMealplan,
  AvailabilityRoom,
  AvailabilityFlatRate,
  howCouponAppliesInRate,
  AvailabilityRate,
} from 'src/core/Availability/domain/Availability.model'
import { OnRateSelected } from 'src/ui/views/AvailableRooms/AvailableRooms.model'
import { useTrans } from 'src/ui/hooks/useTrans'
import { MealplansFilter } from './MealplansFilter'
import { TypeRateSelector } from './TypeRateSelector'
import { CurrentRatePrice } from './CurrentRatePrice'
import { Button, Divider } from 'src/ui/components'
import { Media } from 'src/ui/styles/objects/Media'
import { Flex } from 'src/ui/styles/objects/Flex'
import { RatePoliciesSelector } from './RatePoliciesSelector'
import { MealplanTag } from './MealplanTag'
import { useMarket } from 'src/ui/contexts/MarketContext'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useCoupons } from 'src/ui/contexts/CouponsContext'
import { classNames } from 'src/ui/utils/classnames'
import {
  buildRatePoliciesFrom,
  getAvailableRatesFrom,
  getRatePoliciesToSelect,
  RatePolicies,
} from './RateSelector.model'
import { useRateSelector } from 'src/ui/contexts/RateSelectorContext'
import styles from './RateSelector.module.scss'

interface Props {
  room: AvailabilityRoom
  onRateSelected: OnRateSelected
  isMultiroom: boolean
  isOneNight: boolean
  totalNightsFromAvailability: number
  reservationInProgress: boolean
  defaultMealplan: AvailabilityMealplan
  defaultRatePolicies: RatePolicies
  defaultRate: AvailabilityFlatRate
}

export const RateSelectorInSelectedRoom: FC<Props> = ({
  room,
  onRateSelected,
  totalNightsFromAvailability,
  isOneNight,
  reservationInProgress,
  isMultiroom,
  defaultMealplan,
  defaultRatePolicies,
  defaultRate,
}) => {
  const { trans } = useTrans(['new-reservation', 'common'])
  const { promotionalCoupon } = useCoupons()
  const {
    setSelectedGlobalMealplan,
    selectedGlobalRate,
    setSelectedGlobalRate,
    selectedGlobalRatePolicies,
    setSelectedGlobalRatePolicies,
  } = useRateSelector()
  const { marketPrice } = useMarket()

  const isMarketWithNetPrice = marketPrice?.netPrices

  const [selectedMealplan, setSelectedMealplan] =
    useState<AvailabilityMealplan>(defaultMealplan)
  const [selectedRatePolicy, setSelectedRatePolicy] =
    useState<RatePolicies>(defaultRatePolicies)
  const [selectedRate, setSelectedRate] =
    useState<AvailabilityFlatRate>(defaultRate)

  const hasOnlyOneMealplan = room.mealplans.length === 1
  const selectableRates = getAvailableRatesFrom(selectedRatePolicy)

  const howCouponApplies = () => {
    const baseRate = selectedRatePolicy.rate
    return howCouponAppliesInRate(baseRate, promotionalCoupon)
  }

  const selectRateForMealplan = (
    ratesPolicies: RatePolicies[],
    globalRatePolicy: RatePolicies | undefined,
    globalRate: AvailabilityFlatRate | undefined,
  ): {
    selectedRatePolicy: RatePolicies
    selectedRate: AvailabilityFlatRate
  } => {
    const selectedRatePolicy = globalRatePolicy
      ? getRatePoliciesToSelect(
          ratesPolicies,
          globalRatePolicy,
          selectedMealplan.id.length,
        ) || ratesPolicies[0]
      : ratesPolicies[0]

    let selectedRate: AvailabilityRate | AvailabilityRate['relatedRate'] =
      selectedRatePolicy.rate

    if (isDefined(globalRate) && selectedRatePolicy.rate.id !== globalRate.id) {
      selectedRate =
        globalRate.isRelated && isDefined(selectedRatePolicy.rate.relatedRate)
          ? selectedRatePolicy.rate.relatedRate.rate
          : selectedRatePolicy.rate
    }

    return { selectedRatePolicy, selectedRate }
  }

  const handleMealplanSelection = (mealplan: AvailabilityMealplan) => {
    const ratesPolicies = buildRatePoliciesFrom(mealplan, trans)
    const { selectedRatePolicy, selectedRate } = selectRateForMealplan(
      ratesPolicies,
      selectedGlobalRatePolicies,
      selectedGlobalRate,
    )

    setSelectedMealplan(mealplan)
    setSelectedGlobalMealplan(mealplan)
    setSelectedRatePolicy(selectedRatePolicy)
    setSelectedRate(selectedRate)
  }

  const handleRatePoliciesSelection = (ratePolicies: RatePolicies) => {
    setSelectedRatePolicy(ratePolicies)
    setSelectedGlobalRatePolicies(ratePolicies)

    let rate = ratePolicies.rate
    if (isDefined(ratePolicies.rate.relatedRate) && selectedRate.isRelated) {
      rate = ratePolicies.rate.relatedRate.rate
    }
    setSelectedRate(rate)
    setSelectedGlobalRate(rate)
  }

  const handleRateSelection = (rate: AvailabilityFlatRate) => {
    setSelectedRate(rate)
    setSelectedGlobalRate(rate)
  }

  return (
    <div className={styles.container}>
      {hasOnlyOneMealplan ? (
        <MealplanTag
          mealplan={selectedMealplan}
          className={styles.mealplanFilterContainer}
        />
      ) : (
        <MealplansFilter
          mealplans={room.mealplans}
          selectedMealplan={selectedMealplan}
          onMealplanSelected={handleMealplanSelection}
          className={styles.mealplanFilterContainer}
        />
      )}

      <Media laptop desktop>
        <Divider dir="horizontal" className={styles.divider} />
      </Media>

      <div className={styles.rateSelectorContainer}>
        <RatePoliciesSelector
          ratesPolicies={buildRatePoliciesFrom(selectedMealplan, trans)}
          room={room}
          currentRatePolicies={selectedRatePolicy}
          onRatePoliciesSelected={handleRatePoliciesSelection}
          selectedRate={selectedRate}
        />

        <TypeRateSelector
          room={room}
          selectableRates={selectableRates}
          currentRate={selectedRate}
          onRateSelected={handleRateSelection}
          howCouponApplies={howCouponApplies()}
          totalNightsFromAvailability={totalNightsFromAvailability}
        />
      </div>

      <Flex
        direction="column"
        alignItems="flex-end"
        data-testid={`reserve_container-${room.id}_${selectedRate.id}`}
        className={classNames(
          styles.currentPriceContainer,
          !isMultiroom && styles.isSingleRoom,
        )}
      >
        <CurrentRatePrice
          rate={selectedRate}
          isOneNight={isOneNight}
          howCouponApplies={howCouponApplies()}
        />
        <Button
          data-target-price={
            isMarketWithNetPrice
              ? Math.round(selectedRate.total.netPrice.value)
              : Math.round(selectedRate.total.grossPrice.value)
          }
          data-target-gross-price={Math.round(
            selectedRate.total.grossPrice.value,
          )}
          onClick={() =>
            onRateSelected(
              room.id,
              room.name,
              { id: selectedMealplan.id, name: selectedMealplan.name },
              selectedRate,
            )
          }
          isLoading={reservationInProgress}
          size={{ mobile: 'smallFullWidth', laptop: 'fullWidth' }}
          data-testid={`reserve_button-${room.id}_${selectedRate.id}`}
          data-rate-code={selectedRate.id}
        >
          {trans('available-rooms_select-rate')}
        </Button>
      </Flex>
    </div>
  )
}
