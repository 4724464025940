import styles from './FastBookingMobile.module.scss'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Image } from 'src/ui/components/atoms/Image'
import { getHotelImageLoader } from 'src/ui/utils/images'
import { Text } from 'src/ui/components/atoms/Text'
import { FastBookingMobileSkeleton } from 'src/ui/views/AvailableRooms/FastBookingMobile/FastBookingMobileSkeleton'
import { FC } from 'react'
import { useTrans } from 'src/ui/hooks/useTrans'
import { TripadvisorRatings } from 'src/ui/views/_components/TripadvisorRatings'
import { Flex } from 'src/ui/styles/objects/Flex'
import { HotelPromotionBanner } from '../HotelPromotionBanner/HotelPromotionBanner'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { datesManager } from 'src/core/Shared/infrastructure/datesManager'
import { ButtonLink } from 'src/ui/components/atoms/ButtonLink'
import PencilIcon from 'src/ui/components/atoms/Icon/_icons/interaction/PencilIcon'
import SuccessIcon from 'src/ui/components/atoms/Icon/_icons/system/alert/SuccessIcon'
import { classNames } from 'src/ui/utils/classnames'
import ChevronIcon from 'src/ui/components/atoms/Icon/_icons/navigation/ChevronIcon'
import { useHotelFastBooking } from '../useHotelFastBooking'
import CalendarIcon from 'src/ui/components/atoms/Icon/_icons/general/CalendarIcon'
import BedIcon from 'src/ui/components/atoms/Icon/_icons/general/BedIcon'
import { useLanguageConfig } from 'src/ui/contexts/LanguageConfigContext'
import ErrorFilledIcon from 'src/ui/components/atoms/Icon/_icons/system/alert/ErrorFilledIcon'
import { isFeatureFlagEnabled } from 'src/core/Shared/infrastructure/featureFlags'

interface Props {
  onModifyOccupancy: () => void
  onModifyDates: () => void
  onModifyCoupon: () => void
  onHotelDetailsClick: () => void
}

export const FastBookingMobile: FC<Props> = ({
  onModifyOccupancy,
  onModifyDates,
  onModifyCoupon,
  onHotelDetailsClick,
}) => {
  const { trans } = useTrans(['new-reservation'])
  const {
    hotel,
    availabilityCriteria,
    hasDestination,
    hasHotelAvailabilityNotFoundError,
    nights,
    guestResume,
    getCouponLinkText,
    isCouponApplied,
  } = useHotelFastBooking()

  const languageConfig = useLanguageConfig()
  const isRTL = languageConfig.properties.isRTL

  const isHotelDetailModalOpenable = isFeatureFlagEnabled(
    'FF_FEATURE_5197_HOTEL_DETAIL_MODAL',
  )

  if (isUndefined(hotel) || isUndefined(availabilityCriteria)) {
    return <FastBookingMobileSkeleton />
  }

  return (
    <div data-testid="fastbooking-mobile">
      <Text
        as="p"
        className="mb-s"
        fontStyle={{ mobile: 'm-500', tablet: 'l-500' }}
      >
        {hotel.name}
      </Text>

      <HotelPromotionBanner />

      <div className="mb-s" />

      <div className={styles.heroDataWrapper}>
        <button
          onClick={() => {
            if (isHotelDetailModalOpenable) {
              onHotelDetailsClick()
            }
          }}
          className={classNames(
            styles.hotelWrapper,
            isHotelDetailModalOpenable && styles.isOpenable,
          )}
        >
          <Image
            alt={hotel.name}
            src={hotel.image}
            aria-hidden="true"
            height={96}
            width={96}
            quality={75}
            loader={getHotelImageLoader}
            priority={true}
          />
          <div className="grow">
            {hasDestination && (
              <Text as="p" fontStyle="s-300">
                {hotel.destination}
              </Text>
            )}
            <TripadvisorRatings
              gap="small"
              reviews={hotel.review}
              className="mt-xs"
            />
          </div>
          {isHotelDetailModalOpenable && (
            <div className={classNames(styles.openIcon, 'mr-xs shrink-0')}>
              <Icon icon={ChevronIcon} size="s" rotate={isRTL ? -180 : 0} />
            </div>
          )}
        </button>

        <Flex direction="column" className="w-full">
          <div className={styles.heroLineDataWrapper}>
            {hasHotelAvailabilityNotFoundError ? (
              <Icon
                size="l"
                color="support-error"
                icon={ErrorFilledIcon}
                testId="cancel-icon"
                className={styles.noDatesIcon}
              />
            ) : (
              <Icon size="l" icon={CalendarIcon} className="mr-xs" />
            )}
            <span className={styles.heroLineEllipsisText}>
              <Text fontStyle="s-500">
                <span className={styles.datesRange}>
                  {datesManager.formatDateRangeToLocaleWithYearAndWeekDay({
                    checkIn: availabilityCriteria.checkIn,
                    checkOut: availabilityCriteria.checkOut,
                  })}
                  . &nbsp;
                </span>
                <span>{nights}</span>
              </Text>
            </span>
            <ButtonLink
              className={styles.editButton}
              onClick={onModifyDates}
              data-testid="edit-dates"
              aria-label={trans('availability_hero_edit-dates-label')}
            >
              {trans('availability_hero_edit')}
            </ButtonLink>
          </div>

          <div className={styles.heroLineDataWrapper}>
            <Icon size="l" icon={BedIcon} className="mr-xs" />
            <span className={styles.heroLineEllipsisText}>{guestResume}</span>
            <ButtonLink
              className={styles.editButton}
              onClick={onModifyOccupancy}
              data-testid="edit-guest"
              aria-label={trans('availability_hero_edit-guest-label')}
            >
              {trans('availability_hero_edit')}
            </ButtonLink>
          </div>

          <Flex
            gap="xs"
            alignItems="center"
            className="w-full touch-security-zone-always"
          >
            <button
              onClick={onModifyCoupon}
              data-testid="edit-coupon"
              className="flex items-center gap-xs cursor-pointer"
            >
              <Text as="span" fontStyle="m-500" className={styles.couponLink}>
                {getCouponLinkText()}
              </Text>
              {isCouponApplied && <Icon icon={PencilIcon} size="m" />}
            </button>

            {isCouponApplied && (
              <>
                <div className="grow">&nbsp;</div>
                <Flex className="shrink-0" alignItems="center">
                  <Text fontStyle="s-500" color="support-success">
                    {trans('availability_hero_coupon-added')}
                  </Text>
                  <Icon icon={SuccessIcon} size="s" className="ml-xs" />
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
      </div>
    </div>
  )
}
