import { FC } from 'react'

export const BComBlackIcon: FC = () => (
  <>
    <circle cx="12" cy="12" r="12" fill="#31383B" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.729 11.4966C16.6742 10.9215 17.1346 10.0928 17.1346 8.97306C17.1346 6.8333 15.8451 5.79346 13.1921 5.79346H7.58855C7.5113 5.79346 7.44867 5.85767 7.44867 5.93717V18.1834C7.44867 18.2629 7.5113 18.3273 7.58855 18.3273H13.3591C15.3622 18.3273 17.7018 17.3598 17.7018 14.6329C17.7018 13.0944 17.0043 11.993 15.729 11.4966ZM14.3196 9.43627C14.3196 10.5222 13.5251 10.7502 12.8582 10.7502H10.3464V8.17343H12.6581C13.7917 8.17343 14.3196 8.57465 14.3196 9.43627ZM14.8032 14.4792C14.8032 15.4673 14.2162 15.9476 13.0085 15.9476H10.3464V12.8737H13.0585C14.2326 12.8737 14.8032 13.3988 14.8032 14.4792Z"
      fill="white"
    />
  </>
)

export default BComBlackIcon
