import { FC } from 'react'

interface Props {
  color: string
}

export const ExtraIcon: FC<Props> = ({ color }) => (
  <>
    <ellipse
      cx="11.9805"
      cy="11.9785"
      rx="10.7812"
      ry="10.7812"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6682 6.14006L14.146 9.3246H17.021C17.318 9.31294 17.5904 9.48905 17.7017 9.76473C17.813 10.0404 17.7393 10.3563 17.5174 10.5541L15.0257 13.0094L16.4086 16.1853C16.5324 16.4835 16.4524 16.8273 16.2097 17.0402C15.9671 17.2532 15.6158 17.2878 15.3362 17.1264L11.9945 15.2471L8.64898 17.1264C8.36943 17.2878 8.01809 17.2532 7.77545 17.0402C7.53282 16.8273 7.45284 16.4835 7.5766 16.1853L8.95948 13.0113L6.46781 10.5561C6.24414 10.3596 6.16833 10.0437 6.27851 9.76715C6.38869 9.49062 6.66101 9.31338 6.95848 9.3246H9.83348L11.3093 6.14006C11.4415 5.88709 11.7033 5.72852 11.9888 5.72852C12.2742 5.72852 12.536 5.88709 12.6682 6.14006Z"
      fill="white"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default ExtraIcon
