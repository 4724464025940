import { FC } from 'react'

interface Props {
  color: string
}

export const DiamondIcon: FC<Props> = ({ color }) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.5542 7.45771L26.6303 0.885889C26.212 0.328209 25.5556 0 24.8585 0H7.14118C6.4441 0 5.78769 0.328209 5.36944 0.885889L0.442546 7.45771C-0.173001 8.27896 -0.143671 9.41555 0.513416 10.204L14.3005 26.8882C14.7213 27.3907 15.343 27.681 15.9984 27.681C16.6538 27.681 17.2755 27.3907 17.6963 26.8882L31.4833 10.204C32.1404 9.41555 32.1697 8.27896 31.5542 7.45771ZM15.9984 26.2462H16.9296L30.7151 9.56373C31.0755 9.13131 31.0916 8.50791 30.754 8.05746L25.8302 1.48588C25.6008 1.18001 25.2408 1 24.8585 1H7.14118C6.75892 1 6.39896 1.17995 6.16956 1.48574M6.16956 1.48574L1.24273 8.05746C0.905187 8.5079 0.921228 9.13134 1.2816 9.56374L1.28428 9.56696L15.0671 26.2462L15.0689 26.2483C15.2997 26.5226 15.6399 26.681 15.9984 26.681C16.3569 26.681 15.7676 26.5204 15.9984 26.2462"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.15614 0.454563C6.41506 0.358548 6.70278 0.490603 6.7988 0.749516L9.35213 7.63484L15.4521 0.596363C15.5471 0.486782 15.685 0.423828 15.83 0.423828C15.975 0.423828 16.1129 0.486782 16.2078 0.596363L22.6266 8.00265L25.3617 0.747002C25.4591 0.488609 25.7475 0.3581 26.0059 0.455502C26.2643 0.552905 26.3948 0.841335 26.2974 1.09973L23.5607 8.35962H31.33C31.6061 8.35962 31.83 8.58347 31.83 8.85962C31.83 9.13576 31.6061 9.35962 31.33 9.35962H23.1838L16.4679 27.1759C16.3943 27.3711 16.2073 27.5001 15.9987 27.4995C15.7901 27.499 15.6037 27.369 15.5312 27.1734L8.92519 9.35962H0.5C0.223858 9.35962 0 9.13576 0 8.85962C0 8.58347 0.223858 8.35962 0.5 8.35962H8.55436L5.86119 1.09722C5.76517 0.838302 5.89723 0.550577 6.15614 0.454563ZM9.99174 9.35962L16.0038 25.5718L22.1151 9.35962H9.99174ZM21.6127 8.35962H10.0473L15.83 1.68727L21.6127 8.35962Z"
      fill={color}
    />
  </>
)
