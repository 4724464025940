import { Button, Card, Link, Text } from 'src/ui/components'
import { Flex } from 'src/ui/styles/objects/Flex'
import styles from './AvailabilityError.module.scss'
import {
  AvailabilityError as AvailabilityErrorModel,
  AvailabilityErrorType,
} from 'src/core/Availability/domain/AvailabilityError'
import { FC } from 'react'
import { useTrans } from 'src/ui/hooks/useTrans'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { useCallCenter } from 'src/ui/hooks/useCallCenter'
import { Translate } from 'src/ui/i18n/Translate'
import { NearbyHotels } from './NearbyHotels'
import { NearbyHotel } from 'src/core/Hotel/domain/Hotel.model'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { NearbyHotelsSkeleton } from './NearbyHotels/NearbyHotelsSkeleton'

interface Props {
  error: AvailabilityErrorModel
  nearbyHotels: NearbyHotel[] | undefined
  areNearbyHotelsLoading: boolean
  onChangeDates: () => void
  onReserve: (hotelId: string) => void
}

export const AvailabilityError: FC<Props> = ({
  error,
  nearbyHotels,
  areNearbyHotelsLoading,
  onChangeDates,
  onReserve,
}) => {
  return error.type ===
    AvailabilityErrorType.HOTEL_AVAILABILITY_NOT_FOUND_ERROR ? (
    <NotFoundError
      onChangeDates={onChangeDates}
      onReserve={onReserve}
      nearbyHotels={nearbyHotels}
      areNearbyHotelsLoading={areNearbyHotelsLoading}
    />
  ) : (
    <GenericError />
  )
}

interface NotFoundErrorProps {
  areNearbyHotelsLoading: boolean
  nearbyHotels: NearbyHotel[] | undefined
  onChangeDates: () => void
  onReserve: (hotelId: string) => void
}

const NotFoundError: FC<NotFoundErrorProps> = ({
  areNearbyHotelsLoading,
  nearbyHotels,
  onChangeDates,
  onReserve,
}) => {
  const { trans } = useTrans(['new-reservation'])

  return (
    <div className={styles.container}>
      <div
        data-target-has-no-availability={true}
        data-quantum-warning="no availability"
      >
        <header className={styles.header}>
          <Flex direction="column">
            <Text fontStyle={{ mobile: 'l-700', tablet: 'xl-700' }} as="h2">
              {trans('availability-error_not-found_title-revamped')}
            </Text>
            <Text fontStyle={{ mobile: 'm-300', tablet: 'l-300' }}>
              {trans('availability-error_not-found_subtitle-revamped')}
            </Text>
            <Button className="mt-m" onClick={onChangeDates} size="large">
              {trans('availability-error_not-found_change-dates')}
            </Button>
          </Flex>
        </header>
        <section className={styles.nearbyHotels}>
          <Flex direction="column" className="mt-xl">
            {areNearbyHotelsLoading ? (
              <NearbyHotelsSkeleton />
            ) : (
              isDefined(nearbyHotels) && (
                <NearbyHotels
                  onReserve={onReserve}
                  nearbyHotels={nearbyHotels}
                />
              )
            )}
          </Flex>
        </section>
      </div>
    </div>
  )
}

const GenericError = () => {
  const { trans } = useTrans(['common'])
  const { reload } = useApplicationRouter()
  const { callCenterCountryCode, callCenterPhone, callCenterFreePhone } =
    useCallCenter()

  return (
    <div className={styles.container}>
      <Card mobileDark>
        <div data-quantum-error="availability generic error">
          <Text fontStyle={{ mobile: 'l-700', tablet: '2xl-700' }} as="h2">
            {trans('generic-error_title')}
          </Text>

          <Flex direction="column" gap="m" className="mt-m">
            <Text fontStyle="m-300">{trans('generic-error_description')}</Text>

            <Text fontStyle="m-300">
              <Translate
                i18nKey="generic-error_call-us"
                values={{
                  free: `${callCenterPhone}${callCenterFreePhone}`,
                  moreOptions: trans('generic-error_other-options'),
                }}
                components={{
                  regular: <Text as="p" fontStyle="m-300" />,
                  bold: (
                    <Link
                      data-data-phone={callCenterCountryCode}
                      link={`tel:${callCenterPhone}`}
                      variant="secondary"
                    >
                      {`${callCenterPhone}${callCenterFreePhone}`}
                    </Link>
                  ),
                  options: (
                    <Text fontStyle="m-300">
                      {trans('generic-error_other-options')}
                    </Text>
                  ),
                }}
              />
            </Text>
          </Flex>
          <Button type="submit" size="large" onClick={reload} className="mt-m">
            {trans('generic-error_reload')}
          </Button>
        </div>
      </Card>
    </div>
  )
}
