import { FC } from 'react'
import styles from './Card.module.scss'
import { useTrans } from 'src/ui/hooks/useTrans'
import { ButtonLink } from 'src/ui/components/atoms/ButtonLink'
import { User, UserLevelV2 } from 'src/core/User/domain/User'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Media } from 'src/ui/styles/objects/Media'
import { Button, Text } from 'src/ui/components'

interface Props {
  user: User | undefined
  onClick: () => void
}

export const Card: FC<Props> = ({ user, onClick }) => {
  const { trans } = useTrans(['common', 'new-reservation'])

  const userBenefitsKeys = {
    [UserLevelV2.NotFound]: [
      'benefits_no-level-user_bullet-1_title',
      'benefits_no-level-user_bullet-2_title',
      'benefits_no-level-user_bullet-3_title',
      'benefits_no-level-user_bullet-4_title',
    ],
    [UserLevelV2.Essential]: [
      'benefits_essential-user_bullet-1_title',
      'benefits_essential-user_bullet-2_title',
      'benefits_essential-user_bullet-3_title',
      'benefits_essential-user_bullet-4_title',
    ],
    [UserLevelV2.Special]: [
      'benefits_special-user_bullet-1_title',
      'benefits_special-user_bullet-2_title',
      'benefits_special-user_bullet-3_title',
      'benefits_special-user_bullet-4_title',
    ],
    [UserLevelV2.Unique]: [
      'benefits_unique-user_bullet-1_title',
      'benefits_unique-user_bullet-2_title',
      'benefits_unique-user_bullet-3_title',
      'benefits_unique-user_bullet-4_title',
    ],
  }

  const benefitKeys = isDefined(user)
    ? userBenefitsKeys[user.level as UserLevelV2]
    : userBenefitsKeys[UserLevelV2.NotFound]

  const userConfig = {
    benefits: benefitKeys.map(key => ({
      title: trans(key),
    })),
  }

  const userNotFound =
    isUndefined(user?.level) || user.level === UserLevelV2.NotFound

  const getButtonText = () => {
    return userNotFound
      ? trans(
          'new-reservation:available-rooms_my-barcelo-small-banner_public-link-revamp_no-user-level',
        )
      : trans(
          'new-reservation:available-rooms_my-barcelo-small-banner_public-link-revamp',
        )
  }

  function onButtonClickHandler() {
    onClick()
  }

  return (
    <div className={styles.card}>
      <ul className={styles.list}>
        {userConfig.benefits.map((benefit, index) => {
          return (
            <li key={index}>
              <Text fontStyle="m-700">{benefit.title}</Text>
            </li>
          )
        })}
      </ul>
      <Media desktop>
        <ButtonLink
          className="mt-l"
          variant="myBarcelo"
          onClick={onButtonClickHandler}
        >
          {getButtonText()}
        </ButtonLink>
      </Media>
      <Media laptop tablet mobile>
        <Button
          className={styles.benefitsButton}
          variant="myBarcelo-secondary"
          onClick={onButtonClickHandler}
        >
          {getButtonText()}
        </Button>
      </Media>
    </div>
  )
}
