import { ReservationRepository } from 'src/core/Reservation/domain/Reservation.repository'
import { ViewingAccelerator } from 'src/core/Shared/domain/Reservation.model'

export const getViewingNowAccelerators =
  ({ reservationRepository }: Dependencies) =>
  async (
    hotelId: string,
    checkIn: string,
    checkOut: string,
  ): Promise<ViewingAccelerator[]> => {
    return reservationRepository.getViewingNowAccelerators(
      hotelId,
      checkIn,
      checkOut,
    )
  }

interface Dependencies {
  reservationRepository: ReservationRepository
}
