import { FC } from 'react'
import styles from './MyBarceloBannerV2.module.scss'
import { Text } from 'src/ui/components'
import { Card } from './Card'
import { classNames } from 'src/ui/utils/classnames'
import { useUser } from 'src/ui/contexts/UserContext'
import { UserLevelV2 } from 'src/core/User/domain/User'
import { Translate } from 'src/ui/i18n/Translate'
import { Flex } from 'src/ui/styles/objects/Flex'
import { MyBarceloBenefitsLogoVertical } from 'src/ui/views/_components/MyBarceloBenefitsLogo'

interface Props {
  className?: string
  onClick: () => void
}

export const MyBarceloBannerV2: FC<Props> = ({ className, onClick }) => {
  const { user } = useUser()

  const isUniqueUser = user?.level === UserLevelV2.Unique

  const getTranslation = () => {
    if (isUniqueUser) {
      return 'new-reservation:available-rooms_my-barcelo-small-banner_public-revamp_unique'
    }
    return 'new-reservation:available-rooms_my-barcelo-small-banner_public-revamp'
  }

  return (
    <section className={classNames(styles.container, className)}>
      <div className={classNames(styles.contentWrapper)}>
        <div
          className={classNames(
            styles.background,
            styles[user?.level ?? UserLevelV2.NotFound],
          )}
        >
          <div className={classNames(styles.titleWrapper)}>
            <Flex alignItems="flex-start">
              <MyBarceloBenefitsLogoVertical
                userLevel={user?.level as UserLevelV2}
                variant={isUniqueUser ? 'light' : 'dark'}
              />
            </Flex>
            <Translate
              i18nKey={getTranslation()}
              components={{
                regular: (
                  <Text
                    as="h3"
                    fontStyle={{
                      mobile: 'xl-500',
                      laptop: '2xl-500',
                      desktop: 'xl-500',
                      tablet: '2xl-500',
                    }}
                    color={isUniqueUser ? 'light' : 'dark'}
                    className={styles.title}
                    family="barlowCondensed"
                    uppercase
                  />
                ),
                bold: (
                  <Text
                    fontStyle={{
                      mobile: 'xl-700',
                      laptop: '2xl-700',
                      desktop: 'xl-700',
                      tablet: '2xl-700',
                    }}
                    color={isUniqueUser ? 'light' : 'dark'}
                    family="barlowCondensed"
                  />
                ),
              }}
            />
          </div>
        </div>
        <Card user={user} onClick={onClick} />
      </div>
    </section>
  )
}
