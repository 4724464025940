import styles from './Info.module.scss'

import { FC } from 'react'
import { getRoomInfoFromHotel, Hotel } from 'src/core/Hotel/domain/Hotel.model'
import { Carousel, Text } from 'src/ui/components'
import {
  getRoomI18nImages,
  getRoomImageLoader,
  getRoomServices,
  getServiceImageLoader,
} from 'src/ui/utils/images'
import { Flex } from 'src/ui/styles/objects/Flex'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Image } from 'src/ui/components/atoms/Image'
import { AvailabilityRoom } from 'src/core/Availability/domain/Availability.model'
import { InfoSkeleton } from './InfoSkeleton'
import { Translator } from 'src/ui/hooks/useTrans'
import { Tooltip } from 'src/ui/components/molecules/Tooltip'
import { ButtonLink } from 'src/ui/components/atoms/ButtonLink'

interface Props {
  room: AvailabilityRoom
  hotel: Hotel | undefined
  trans: Translator | any
  onMoreInfoClick: () => void
}

export const Info: FC<Props> = ({ hotel, room, trans, onMoreInfoClick }) => {
  const isHotelLoading = isUndefined(hotel)

  if (isHotelLoading) {
    return <InfoSkeleton />
  }

  const roomInfo = getRoomInfoFromHotel(room, hotel)
  const showMaxGuests = roomInfo.guests > 0
  const services = getRoomServices(room, hotel)
  const hasServices = services.length > 0

  return (
    <div className={styles.container}>
      <div className={styles.carouselContainer}>
        <Carousel
          isRounded
          imageSlides={getRoomI18nImages(trans)(room, hotel)}
          alt={trans('available-rooms_carousel_alt', { roomName: room.name })}
          loader={getRoomImageLoader({
            aspectRatio: { width: 16, height: 9 },
          })}
        />
      </div>

      <div className={styles.detailsContainer}>
        {(isDefined(roomInfo.size) || showMaxGuests) && (
          <div className={styles.details}>
            {showMaxGuests && (
              <Flex gap="xs" alignItems="center">
                <Text fontStyle="m-300" color="dark">
                  {trans('available-rooms_max-guests-number', {
                    guests: roomInfo.guests,
                  })}
                </Text>
              </Flex>
            )}
            {isDefined(roomInfo.size) && (
              <Text fontStyle="m-300" color="dark">
                {trans('available-rooms_size', {
                  size: roomInfo.size.value,
                })}
              </Text>
            )}
          </div>
        )}

        {roomInfo.description && (
          <Text fontStyle="m-300">{roomInfo.description}</Text>
        )}

        {hasServices && (
          <div
            className={styles.services}
            aria-label={trans('available-rooms_services')}
          >
            {services.map(({ text, icon: { alt, src } }) => (
              <div key={text} className={styles.service}>
                <Tooltip
                  tooltipContent={text}
                  triggerElement={
                    <Image
                      alt={alt}
                      src={src}
                      width={24}
                      height={24}
                      className={styles.serviceImage}
                      quality={75}
                      loader={getServiceImageLoader}
                    />
                  }
                />
              </div>
            ))}
          </div>
        )}

        <ButtonLink onClick={onMoreInfoClick}>
          {trans('available-rooms_more-info-text')}
        </ButtonLink>
      </div>
    </div>
  )
}
