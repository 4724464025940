import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { TripadvisorIcon } from 'src/ui/components/atoms/Icon/_icons/social/TripadvisorIcon'
import styles from './TripadvisorRatings.module.scss'
import { DotRatings } from './DotRatings'
import { Flex } from 'src/ui/styles/objects/Flex'
import { FC } from 'react'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Text } from 'src/ui/components'
import { useTrans } from 'src/ui/hooks/useTrans'
import {
  getHotelTripadvisorRankLevel,
  TripadvisorRankLevel,
} from 'src/core/Hotel/domain/Hotel.model'

interface Props {
  gap: 'small' | 'large'
  reviews: {
    count?: number
    averageRate?: string
  }
  className?: string
  showRankLevelName?: boolean
}

export const TripadvisorRatings: FC<Props> = ({
  gap,
  reviews,
  className,
  showRankLevelName = true,
}) => {
  const hasReviewsCount = isDefined(reviews.count)
  const { trans } = useTrans(['common', 'new-reservation'])

  const rankLevelNames: Record<TripadvisorRankLevel, string> = {
    veryGood: trans('tripadvisor_average-name_level1'),
    excellent: trans('tripadvisor_average-name_level2'),
  }

  if (isUndefined(reviews.averageRate)) {
    return null
  }

  if (parseFloat(reviews.averageRate) < 4) {
    return null
  }

  return (
    <div
      aria-label={trans('new-reservation:tripadvisor_average-aria-label', {
        rating: reviews.averageRate,
      })}
    >
      <Flex
        gap={gap === 'small' ? 'xxs' : 'xs'}
        direction="row"
        alignItems="center"
        className={className}
        wrap="wrap"
      >
        <Icon ariaHidden size="l" icon={TripadvisorIcon} color="icon-dark" />
        <Text ariaHidden fontStyle={{ mobile: 'm-700', laptop: 'l-700' }}>
          {reviews.averageRate}
        </Text>
        {showRankLevelName && (
          <Text fontStyle="m-700" className={styles.averageName}>
            {rankLevelNames[getHotelTripadvisorRankLevel(reviews.averageRate)]}
          </Text>
        )}
        <div className={styles.dot} />
        <DotRatings ratings={reviews.averageRate} />
        {hasReviewsCount && (
          <Text
            aria-label={trans(
              'new-reservation:tripadvisor_comments-count-aria-label',
              {
                count: reviews.count,
              },
            )}
            as="div"
            fontStyle={{ mobile: 's-300', laptop: 'm-300' }}
          >
            {`(${reviews.count})`}
          </Text>
        )}
      </Flex>
    </div>
  )
}
