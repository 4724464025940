import { RoomMetadata } from 'src/core/Shared/domain/Reservation.model'
import { CurrencyISOCode } from 'src/core/Shared/domain/Price.model'
import {
  CustomEvent,
  eventsManager,
} from 'src/core/Shared/infrastructure/eventsManager'
import { ReservationRepository } from 'src/core/Reservation/domain/Reservation.repository'
import { CartRepository } from 'src/core/Cart/domain/Cart.repository'
import { Analytics } from 'src/core/Shared/domain/Analytics'
import { StorageClient } from 'src/core/Shared/infrastructure/sessionStorageClient'
import { Availability } from 'src/core/Availability/domain/Availability.model'
import { RatePairStorageRepository } from 'src/core/Shared/infrastructure/RatePair.repository'
import { ReservationCriteria } from 'src/core/Reservation/domain/ReservationCriteria'

export const create =
  ({
    reservationRepository,
    cartRepository,
    analytics,
    sessionStorageClient,
    ratePairStorageRepository,
  }: Dependencies) =>
  async (
    reservationCriteria: ReservationCriteria,
    token: string | undefined,
    currency: CurrencyISOCode,
    availability: Availability,
    rooms: (RoomMetadata | undefined)[],
    coupon: string | undefined,
    groupcode: string | undefined,
  ) => {
    const reservation = await reservationRepository.create(
      reservationCriteria,
      token,
      currency,
      { getConverted: true },
    )
    cartRepository.set(reservation)

    analytics.requests.preReservation(reservation, coupon, groupcode)

    sessionStorageClient.set('hotelMarketingAlertAlreadyShowed', true)

    eventsManager.emit(CustomEvent.SHOWED_MARKETING_ALERT)

    ratePairStorageRepository.saveFrom(availability, rooms)

    return reservation
  }

interface Dependencies {
  reservationRepository: ReservationRepository
  cartRepository: CartRepository
  analytics: Analytics
  sessionStorageClient: StorageClient
  ratePairStorageRepository: RatePairStorageRepository
}
