import React, { FC, useMemo } from 'react'
import { AvailabilityRoom } from 'src/core/Availability/domain/Availability.model'
import {
  ReservedRoomAndRate,
  SelectedRoom,
} from 'src/core/Shared/domain/Reservation.model'
import { OnRateSelected } from '../../AvailableRooms.model'
import { useTrans } from 'src/ui/hooks/useTrans'
import {
  buildRatePoliciesFrom,
  getDefaultMealplan,
  getDefaultRate,
  getDefaultRatePolicies,
} from './RateSelector.model'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { useRateSelector } from 'src/ui/contexts/RateSelectorContext'
import { RateSelector } from './RateSelector'
import { RateSelectorInSelectedRoom } from 'src/ui/views/AvailableRooms/Room/RateSelector/RateSelectorInSelectedRoom'

interface Props {
  room: AvailabilityRoom
  reservedRoomAndRate?: ReservedRoomAndRate
  onRateSelected: OnRateSelected
  selectedRoom: SelectedRoom | undefined
  isMultiroom: boolean
  isOneNight: boolean
  totalNightsFromAvailability: number
  reservationInProgress: boolean
  isSelected: boolean
}

export const RateSelectorController: FC<Props> = ({
  room,
  onRateSelected,
  totalNightsFromAvailability,
  isOneNight,
  selectedRoom: previousSelectedRoom,
  reservationInProgress,
  isMultiroom,
  isSelected,
}) => {
  const { queryUtils } = useApplicationRouter()
  const { trans } = useTrans(['new-reservation', 'common'])
  const { selectedGlobalMealplan } = useRateSelector()

  const mealplanFromURL = queryUtils.getMealplanParam()
  const rateIdFromURL = queryUtils.getRateParam()
  const ratePolicies = room.mealplans.flatMap(mp =>
    buildRatePoliciesFrom(mp, trans),
  )

  const defaultMealplan = useMemo(() => {
    return getDefaultMealplan(
      previousSelectedRoom,
      selectedGlobalMealplan,
      mealplanFromURL,
      room,
    )
  }, [previousSelectedRoom, selectedGlobalMealplan, mealplanFromURL, room])

  const defaultRatePolicies = useMemo(() => {
    return getDefaultRatePolicies(
      defaultMealplan,
      ratePolicies,
      previousSelectedRoom,
      rateIdFromURL,
      trans,
    )
  }, [defaultMealplan, ratePolicies, previousSelectedRoom, rateIdFromURL])

  const defaultRate = useMemo(() => {
    return getDefaultRate(
      defaultRatePolicies,
      previousSelectedRoom,
      room,
      rateIdFromURL,
    )
  }, [defaultRatePolicies, previousSelectedRoom, room, rateIdFromURL])

  return isSelected ? (
    <RateSelectorInSelectedRoom
      room={room}
      onRateSelected={onRateSelected}
      isMultiroom={isMultiroom}
      isOneNight={isOneNight}
      totalNightsFromAvailability={totalNightsFromAvailability}
      reservationInProgress={reservationInProgress}
      defaultMealplan={defaultMealplan}
      defaultRatePolicies={defaultRatePolicies}
      defaultRate={defaultRate}
    />
  ) : (
    <RateSelector
      room={room}
      onRateSelected={onRateSelected}
      selectedRoom={previousSelectedRoom}
      isMultiroom={isMultiroom}
      isOneNight={isOneNight}
      totalNightsFromAvailability={totalNightsFromAvailability}
      reservationInProgress={reservationInProgress}
      defaultMealplan={defaultMealplan}
      defaultRatePolicies={defaultRatePolicies}
      defaultRate={defaultRate}
      rateIdFromURL={rateIdFromURL}
    />
  )
}
