import { Button, Text } from 'src/ui/components'
import styles from './Footer.module.scss'
import { Flex } from 'src/ui/styles/objects/Flex'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Translate } from 'src/ui/i18n/Translate'
import { FC } from 'react'
import { NearbyHotel } from 'src/core/Hotel/domain/Hotel.model'
import { Price } from 'src/ui/components/molecules/Price'

interface Props {
  bestPriceAverage: NearbyHotel['bestPriceAverage']
  id: string
  onReserve: (hotelId: string) => void
}

export const Footer: FC<Props> = ({ bestPriceAverage, id, onReserve }) => {
  const { trans } = useTrans(['new-reservation'])

  const handleOnReserve = () => {
    onReserve(id)
  }

  return (
    <div className={styles.footer}>
      <div>
        <Text fontStyle="xs-300" color="mid">
          {trans('availability-error_not-found_alternative-hotels_price_from')}
        </Text>
        <Flex gap="xxs" alignItems="baseline">
          <Text fontStyle="xs-300" color="mid">
            <Translate
              i18nKey="new-reservation:availability-error_not-found_alternative-hotels_price"
              components={{
                price: (
                  <Price
                    className={styles.price}
                    fontStyle="l"
                    color="primary"
                    grossPrice={bestPriceAverage.grossPrice}
                    netPrice={bestPriceAverage.netPrice}
                    round="up"
                  />
                ),
              }}
            />
          </Text>
        </Flex>
      </div>
      <Button
        className={styles.button}
        variant="secondary"
        size="small"
        onClick={handleOnReserve}
      >
        {trans('available-rooms_member-price-reserve')}
      </Button>
    </div>
  )
}
