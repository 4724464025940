import styles from './HotelPromotions.module.scss'
import { Skeleton, Text } from 'src/ui/components'
import { FC } from 'react'
import { classNames } from 'src/ui/utils/classnames'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import SuccessIcon from 'src/ui/components/atoms/Icon/_icons/system/alert/SuccessIcon'

interface Props {
  hotelPromotions: string[]
  isLoadingPromotions?: boolean
  showAll?: boolean
}

export const HotelPromotions: FC<Props> = ({
  hotelPromotions,
  isLoadingPromotions = false,
  showAll = false,
}) => {
  const promotions = showAll ? hotelPromotions : hotelPromotions?.slice(0, 2)

  return (
    <ul className={styles.list}>
      {promotions.map((promotion, index) => (
        <li
          className={classNames(
            styles.promotion,
            !showAll && styles.withEllipsis,
          )}
          key={index}
        >
          {isLoadingPromotions ? (
            <Skeleton width="100%" height={20} />
          ) : (
            <Flex gap="xs">
              <Icon icon={SuccessIcon} size="s" className={styles.icon} />
              <Text fontStyle="m-300">{promotion}</Text>
            </Flex>
          )}
        </li>
      ))}
    </ul>
  )
}
