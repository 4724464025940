import { ButtonSkeleton, Skeleton } from 'src/ui/components'
import { Flex } from 'src/ui/styles/objects/Flex'
import cardStyles from './NearbyHotelCard.module.scss'
import footerStyles from './Footer/Footer.module.scss'
import { Media } from 'src/ui/styles/objects/Media'

export const NearbyHotelCardSkeleton = () => {
  return (
    <div className={cardStyles.hotelCardContainer}>
      <Flex direction="column">
        <Skeleton height={200} />
        <Flex direction="column" gap="xs" className={cardStyles.hotelInfo}>
          <Skeleton width={150} height={28} />
          <Skeleton width={100} height={20} />
          <Skeleton width={160} height={24} />
        </Flex>
      </Flex>
      <div className={footerStyles.footer}>
        <Flex direction="column">
          <Skeleton width={50} height={16} />
          <Skeleton width={92} height={30} />
        </Flex>

        <Media laptop desktop tablet>
          <div>
            <ButtonSkeleton width={140} height={40} />
          </div>
        </Media>
        <Media mobile>
          <div>
            <ButtonSkeleton height={40} width="100%" />
          </div>
        </Media>
      </div>
    </div>
  )
}
