import { FC } from 'react'
import { UserLevel, UserLevelV2 } from 'src/core/User/domain/User'
import { AllThisIsYours } from '../_components/AllThisIsYours'
import { Advantages } from '../_components/Advantages'
import { LandingHeader } from '../_components/LandingHeader'
import { useTrans } from 'src/ui/hooks/useTrans'

interface Props {
  userLevel: UserLevelV2 | UserLevel
}

export const LoggedUserContent: FC<Props> = ({ userLevel }: Props) => {
  const { trans } = useTrans(['myb-lite-landing'])
  const normalizedUserLevel = normalizeUserLevel({ userLevel })
  return (
    <>
      <LandingHeader
        type={normalizedUserLevel}
        title={trans(`lite_landing-landing_header-title`)}
        description={trans(`lite_landing-landing_header-description`)}
        levelName={trans(
          `lite_landing-advantages-header_title_${normalizedUserLevel}`,
        )}
      />
      <Advantages
        userLevel={normalizedUserLevel}
        title={trans(`lite_landing-${normalizedUserLevel}-advantages-title`)}
      />
      <AllThisIsYours />
    </>
  )
}

const normalizeUserLevel = ({ userLevel }: Props) => {
  if (userLevel === UserLevelV2.Essential || userLevel === UserLevel.Start) {
    return UserLevelV2.Essential
  }
  if (userLevel === UserLevelV2.Special || userLevel === UserLevel.Intense) {
    return UserLevelV2.Special
  }
  return UserLevelV2.Unique
}
