import { FC } from 'react'

interface Props {
  color: string
}

export const DoubleCheckIcon: FC<Props> = ({ color }) => (
  <>
    <path
      d="M31.9739 8.35156L16.7443 28.6576C16.3971 29.1208 15.866 29.4105 15.2885 29.4517C14.7111 29.4928 14.1442 29.2812 13.7349 28.8719L9.77246 24.9094"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.01391 28.9965C8.64101 29.3778 8.13104 29.594 7.59771 29.597C7.06437 29.6 6.55201 29.3895 6.17484 29.0124L2.2124 25.0566"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.2276 8.49219L13.3931 22.9391"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)
