import { Icon } from 'src/ui/components/atoms/Icon'
import ExtraIcon from 'src/ui/components/atoms/Icon/_icons/general/ExtraIcon'
import { ReactNode } from 'react'
import { IconSize } from 'src/ui/styles/settings'
import { DiamondRoundedIcon } from 'src/ui/components/atoms/Icon/_icons/benefits'

export interface Benefit {
  description: string
  value: string
  tooltip?: string
}

export interface BenefitType {
  icon: (size: IconSize) => ReactNode
  title: string
  benefits: Benefit[]
}

export const essentialBenefits: BenefitType[] = [
  {
    icon: size => <Icon size={size} icon={DiamondRoundedIcon} />,
    title: 'lite_landing-advantages-main_benefits-title',
    benefits: [
      {
        description: 'lite_landing-advantages-main_benefits-1',
        value: 'check',
      },
      {
        description: 'lite_landing-advantages-main_benefits-2',
        value: '5%',
      },
      {
        description: 'lite_landing-advantages-main_benefits-3',
        value: 'check',
      },
      {
        description: 'lite_landing-advantages-main_benefits-4',
        value: 'check',
      },
      {
        description: 'lite_landing-advantages-main_benefits-5',
        value: 'check',
        tooltip: 'lite_landing-advantages-main_benefits-5-tooltip',
      },
      {
        description: 'lite_landing-advantages-main_benefits-6',
        value: 'check',
        tooltip: 'lite_landing-advantages-main_benefits-6-tooltip',
      },
      {
        description: 'lite_landing-advantages-main_benefits-7',
        value: 'line',
      },
    ],
  },
  {
    icon: size => <Icon size={size} icon={ExtraIcon} />,
    title: 'lite_landing-advantages-extra_benefits-title',
    benefits: [
      {
        description: 'lite_landing-advantages-extra_benefits-1',
        value: 'check',
        tooltip: 'lite_landing-advantages-extra_benefits-1-tooltip',
      },
      {
        description: 'lite_landing-advantages-extra_benefits-2',
        value: '5%',
        tooltip: 'lite_landing-advantages-extra_benefits-2-tooltip',
      },
      {
        description: 'lite_landing-advantages-extra_benefits-3',
        value: 'line',
      },
      {
        description: 'lite_landing-advantages-extra_benefits-4',
        value: 'line',
      },
      {
        description: 'lite_landing-advantages-extra_benefits-5',
        value: 'line',
      },
      {
        description: 'lite_landing-advantages-extra_benefits-6',
        value: 'line',
        tooltip: 'lite_landing-advantages-extra_benefits-6-tooltip',
      },
      {
        description: 'lite_landing-advantages-extra_benefits-7',
        value: 'line',
      },
      {
        description: 'lite_landing-advantages-extra_benefits-8',
        value: 'line',
      },
    ],
  },
]
export const specialBenefits: BenefitType[] = [
  {
    icon: size => <Icon size={size} icon={DiamondRoundedIcon} />,
    title: 'lite_landing-advantages-main_benefits-title',
    benefits: [
      {
        description: 'lite_landing-advantages-main_benefits-1',
        value: 'check',
      },
      {
        description: 'lite_landing-advantages-main_benefits-2',
        value: '10%',
      },
      {
        description: 'lite_landing-advantages-main_benefits-3',
        value: 'check',
      },
      {
        description: 'lite_landing-advantages-main_benefits-4',
        value: 'check',
      },
      {
        description: 'lite_landing-advantages-main_benefits-5',
        value: 'check',
        tooltip: 'lite_landing-advantages-main_benefits-5-tooltip',
      },
      {
        description: 'lite_landing-advantages-main_benefits-6',
        value: 'check',
        tooltip: 'lite_landing-advantages-main_benefits-6-tooltip',
      },
      {
        description: 'lite_landing-advantages-main_benefits-7',
        value: 'check',
      },
    ],
  },
  {
    icon: size => <Icon size={size} icon={ExtraIcon} />,
    title: 'lite_landing-advantages-extra_benefits-title',
    benefits: [
      {
        description: 'lite_landing-advantages-extra_benefits-1',
        value: 'check',
        tooltip: 'lite_landing-advantages-extra_benefits-1-tooltip',
      },
      {
        description: 'lite_landing-advantages-extra_benefits-2',
        value: '10%',
        tooltip: 'lite_landing-advantages-extra_benefits-2-tooltip',
      },
      {
        description: 'lite_landing-advantages-extra_benefits-3',
        value: 'lite_landing-advantages_every-day',
      },
      {
        description: 'lite_landing-advantages-extra_benefits-4',
        value: 'lite_landing-advantages_yes',
      },
      {
        description: 'lite_landing-advantages-extra_benefits-5',
        value: 'check',
      },
      {
        description: 'lite_landing-advantages-extra_benefits-6',
        value: 'check',
        tooltip: 'lite_landing-advantages-extra_benefits-6-tooltip',
      },
      {
        description: 'lite_landing-advantages-extra_benefits-7',
        value: 'line',
      },
      {
        description: 'lite_landing-advantages-extra_benefits-8',
        value: 'line',
      },
    ],
  },
]

export const uniqueBenefits: BenefitType[] = [
  {
    icon: size => <Icon size={size} icon={DiamondRoundedIcon} />,
    title: 'lite_landing-advantages-main_benefits-title',
    benefits: [
      {
        description: 'lite_landing-advantages-main_benefits-1',
        value: 'check',
      },
      {
        description: 'lite_landing-advantages-main_benefits-2',
        value: '10%',
      },
      {
        description: 'lite_landing-advantages-main_benefits-3',
        value: 'check',
      },
      {
        description: 'lite_landing-advantages-main_benefits-4',
        value: 'check',
      },
      {
        description: 'lite_landing-advantages-main_benefits-5',
        value: 'check',
        tooltip: 'lite_landing-advantages-main_benefits-5-tooltip',
      },
      {
        description: 'lite_landing-advantages-main_benefits-6',
        value: 'check',
        tooltip: 'lite_landing-advantages-main_benefits-6-tooltip',
      },
      {
        description: 'lite_landing-advantages-main_benefits-7',
        value: 'check',
      },
    ],
  },
  {
    icon: size => <Icon size={size} icon={ExtraIcon} />,
    title: 'lite_landing-advantages-extra_benefits-title',
    benefits: [
      {
        description: 'lite_landing-advantages-extra_benefits-1',
        value: 'check',
        tooltip: 'lite_landing-advantages-extra_benefits-1-tooltip',
      },
      {
        description: 'lite_landing-advantages-extra_benefits-2',
        value: '20%',
        tooltip: 'lite_landing-advantages-extra_benefits-2-tooltip',
      },
      {
        description: 'lite_landing-advantages-extra_benefits-3',
        value: 'lite_landing-advantages_every-day',
      },
      {
        description: 'lite_landing-advantages-extra_benefits-4',
        value: 'lite_landing-advantages_premium',
      },
      {
        description: 'lite_landing-advantages-extra_benefits-5',
        value: 'lite_landing-advantages_preference-room',
      },
      {
        description: 'lite_landing-advantages-extra_benefits-6',
        value: 'lite_landing-advantages_yes',
        tooltip: 'lite_landing-advantages-extra_benefits-6-tooltip',
      },
      {
        description: 'lite_landing-advantages-extra_benefits-7',
        value: 'lite_landing-advantages_from-10-am',
      },
      {
        description: 'lite_landing-advantages-extra_benefits-8',
        value: 'lite_landing-advantages_until-16-am',
      },
    ],
  },
]
