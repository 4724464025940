import { Fragment } from 'react'
import styles from './MemberRateBenefitsModal.module.scss'
import { Modal, Text } from 'src/ui/components'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Header } from './Header'
import { User, UserLevel } from 'src/core/User/domain/User'
import { Benefit } from './Benefit'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { BenefitModel } from './Benefit'
import { createModal } from 'src/ui/hooks/useModal'
import { Color } from 'src/ui/styles/settings'

interface Props {
  user: User | undefined
}

export const MemberRateBenefitsModal = createModal(({ user }: Props) => {
  const { trans } = useTrans(['common'])

  const usersMap: {
    [key in UserLevel]: {
      color: string
      textColor: Color
      benefits: Array<BenefitModel>
    }
  } = {
    [UserLevel.NotFound]: {
      color: 'var(--b-color-myBarcelo-start)',
      textColor: 'text-dark',
      benefits: [
        {
          title: trans('benefits_modal_no-level-user_bullet-1_title'),
          text: trans('benefits_modal_no-level-user_bullet-1_text'),
        },
        {
          title: trans('benefits_modal_no-level-user_bullet-2_title'),
          text: trans('benefits_modal_no-level-user_bullet-2_text'),
        },
        {
          title: trans('benefits_modal_no-level-user_bullet-3_title'),
          text: trans('benefits_modal_no-level-user_bullet-3_text'),
        },
      ],
    },
    [UserLevel.Start]: {
      color: 'var(--b-color-myBarcelo-start)',
      textColor: 'text-dark',
      benefits: [
        {
          title: trans('benefits_modal_start-user_bullet-1_title'),
          text: trans('benefits_modal_start-user_bullet-1_text'),
        },
        {
          title: trans('benefits_modal_start-user_bullet-2_title'),
          text: trans('benefits_modal_start-user_bullet-2_text'),
        },
        {
          title: trans('benefits_modal_start-user_bullet-3_title'),
          text: trans('benefits_modal_start-user_bullet-3_text'),
        },
      ],
    },
    [UserLevel.Intense]: {
      color: 'var(--b-color-myBarcelo-intense)',
      textColor: 'text-light',
      benefits: [
        {
          title: trans('benefits_modal_intense-user_bullet-1_title'),
          text: trans('benefits_modal_intense-user_bullet-1_text'),
        },
        {
          title: trans('benefits_modal_intense-user_bullet-2_title'),
          text: trans('benefits_modal_intense-user_bullet-2_text'),
        },
        {
          title: trans('benefits_modal_intense-user_bullet-3_title'),
          text: trans('benefits_modal_intense-user_bullet-3_text'),
        },
        {
          title: trans('benefits_modal_intense-user_bullet-4_title'),
          text: trans('benefits_modal_intense-user_bullet-4_text'),
        },
      ],
    },
    [UserLevel.Unique]: {
      color: 'var(--b-color-myBarcelo-unique)',
      textColor: 'text-light',
      benefits: [
        {
          title: trans('benefits_modal_unique-user_bullet-1_title'),
          text: trans('benefits_modal_unique-user_bullet-1_text'),
        },
        {
          title: trans('benefits_modal_unique-user_bullet-2_title'),
          text: trans('benefits_modal_unique-user_bullet-2_text'),
        },
        {
          title: trans('benefits_modal_unique-user_bullet-3_title'),
          text: trans('benefits_modal_unique-user_bullet-3_text'),
        },
        {
          title: trans('benefits_modal_unique-user_bullet-4_title'),
          text: trans('benefits_modal_unique-user_bullet-4_text'),
        },
      ],
    },
  }

  const userLevel = (user?.level as UserLevel) ?? UserLevel.NotFound
  const userConfigByLevel = usersMap[userLevel]

  return (
    <Modal
      type="passive"
      size="m"
      ariaLabel={trans('benefits_modal_aria-label')}
    >
      <Text
        fontStyle={{ mobile: 'xl-700', tablet: '2xl-700' }}
        color="dark"
        as="h1"
        centered
        className="mb-m"
      >
        {isUndefined(user)
          ? trans('benefits_modal_no-level-user_title')
          : trans('benefits_modal_level-user_title')}
      </Text>
      <>
        <Header color={userConfigByLevel.color} level={userLevel} />

        <ul className={styles.list}>
          {userConfigByLevel.benefits.map((benefit, index) => {
            return (
              <Fragment key={index}>
                <Benefit
                  userConfigByLevel={userConfigByLevel}
                  benefit={benefit}
                />
              </Fragment>
            )
          })}
        </ul>
      </>
    </Modal>
  )
})
