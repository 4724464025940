import styles from './FastBookingMobile.module.scss'
import { Skeleton } from 'src/ui/components'
import { Flex } from 'src/ui/styles/objects/Flex'

export const FastBookingMobileSkeleton = () => {
  return (
    <div>
      <Skeleton height={23} width={200} className="mb-s" />
      <Skeleton height={23} width={200} className="mb-s" />

      <div className="mb-s" />

      <div className={styles.heroDataWrapper}>
        <button className={styles.hotelWrapper}>
          <Skeleton height={96} width={96} />
          <div className="grow">
            <Skeleton height={23} width={200} className="mb-s" />
            <Skeleton height={23} width={200} />
          </div>
        </button>

        <Flex direction="column" className="w-full">
          <div className={styles.heroLineDataWrapper}>
            <div className={styles.heroLineData}>
              <span className={styles.heroLineEllipsisText}>
                <Skeleton height={23} width={200} />
              </span>
            </div>
            <Skeleton height={23} width={40} className="mb-s" />
          </div>

          <div className={styles.heroLineDataWrapper}>
            <div className={styles.heroLineData}>
              <Skeleton height={23} width={200} />
            </div>
            <Skeleton height={23} width={40} className="mb-s" />
          </div>

          <Flex
            gap="xs"
            alignItems="center"
            className="w-full touch-security-zone-always"
          >
            <Skeleton height={23} width={150} className="mb-s" />
          </Flex>
        </Flex>
      </div>
    </div>
  )
}
