import { FC } from 'react'
import { Tag, Text } from 'src/ui/components'
import {
  AvailabilityFlatRate,
  AvailabilityRoom,
} from 'src/core/Availability/domain/Availability.model'

import { useTrans } from 'src/ui/hooks/useTrans'
import { Flex } from 'src/ui/styles/objects/Flex'
import { InputRadio } from '../InputRadio'
import { Media } from 'src/ui/styles/objects/Media'
import styles from './RatePoliciesSelector.module.scss'
import { useModal } from 'src/ui/hooks/useModal'
import { RatePoliciesModal } from 'src/ui/views/_components/RatePoliciesModal'
import { ButtonLink } from 'src/ui/components/atoms/ButtonLink'
import { useMedia } from 'src/ui/hooks/useMedia'
import {
  getAvailableRatesFrom,
  memberRateHasTag,
  RatePolicies,
} from '../RateSelector.model'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

export const RatePoliciesSelector: FC<{
  room: AvailabilityRoom
  ratesPolicies: RatePolicies[]
  currentRatePolicies: RatePolicies
  onRatePoliciesSelected: (ratePolicies: RatePolicies) => void
  selectedRate: AvailabilityFlatRate
}> = ({
  ratesPolicies,
  room,
  currentRatePolicies,
  onRatePoliciesSelected,
  selectedRate,
}) => {
  const { trans } = useTrans(['new-reservation', 'common'])
  const { showModal: showPoliciesModal } = useModal(RatePoliciesModal)
  const { media } = useMedia()

  return (
    <div>
      <Text as="h4" fontStyle="l-700" color="dark" className="mb-s">
        {trans('available-rooms_rate-policies_title')}
      </Text>
      <Flex gap="s" direction="column" alignItems="stretch">
        {ratesPolicies.map((ratePolicies, index) => {
          const isMobileRate =
            ratePolicies.rate.isMobile ||
            ratePolicies.rate.relatedRate?.rate.isMobile

          const isSelected = currentRatePolicies.id === ratePolicies.id

          const isFirstRate = index === 0

          const shouldDisplayTag = () => {
            if (media === 'tablet' || media === 'mobile') {
              return isMobileRate
            }

            const selectableRates = getAvailableRatesFrom(currentRatePolicies)

            if (isFirstRate && memberRateHasTag(selectableRates.memberRate)) {
              return true
            }

            return false
          }

          return (
            <InputRadio
              name={`policies-${room.id}`}
              id={`policies-${room.id}_${ratePolicies.id}`}
              isSelected={isSelected}
              key={`${ratePolicies.id}-${index}`}
              onChange={() => onRatePoliciesSelected(ratePolicies)}
              withTag={shouldDisplayTag()}
            >
              <>
                {isMobileRate && (
                  <Media mobile tablet>
                    <Tag className={styles.mobileTag}>
                      {trans('common:rate_only-mobile')}
                    </Tag>
                  </Media>
                )}
                <Text
                  as="p"
                  fontStyle="m-700"
                  color="dark"
                  className="ml-xl mr-2xl mb-xxs"
                >
                  {ratePolicies.guarantee}
                </Text>
                <Text
                  as="p"
                  fontStyle="s-500"
                  className="ml-xl"
                  color={
                    ratePolicies.isCancellationHighlighted
                      ? 'support-success'
                      : 'dark'
                  }
                >
                  {ratePolicies.cancellation}
                </Text>
                <ButtonLink
                  onClick={() => {
                    const getPoliciesDetails = () => {
                      if (selectedRate.isMember) {
                        return ratePolicies.rate
                      }

                      if (isDefined(ratePolicies.rate.relatedRate)) {
                        return ratePolicies.rate.relatedRate.rate
                      }

                      return ratePolicies.rate
                    }

                    return showPoliciesModal({
                      policiesDetails: getPoliciesDetails(),
                      hasToShowPrices: false,
                    })
                  }}
                  aria-label={trans(
                    'new-reservation:available-rooms_rate-policies_info-hidden',
                  )}
                  className={styles.policiesInfoButton}
                >
                  <Text fontStyle="s-link">
                    {trans('available-rooms_rate-policies_info')}
                  </Text>
                </ButtonLink>
              </>
            </InputRadio>
          )
        })}
      </Flex>
    </div>
  )
}
