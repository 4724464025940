import { FC } from 'react'
import { AvailabilityRate } from 'src/core/Availability/domain/Availability.model'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Text } from 'src/ui/components'
import { useMarket } from 'src/ui/contexts/MarketContext'
import { TaxInformation } from '../TaxInformation'
import { Translate } from 'src/ui/i18n/Translate'
import { Flex } from 'src/ui/styles/objects/Flex'
import { useTrans } from 'src/ui/hooks/useTrans'
import { CouponTag } from '../CouponTag'
import { useCoupons } from 'src/ui/contexts/CouponsContext'
import { StrikethroughPrice } from '../StrikethroughPrice'
import { classNames } from 'src/ui/utils/classnames'
import { Price } from 'src/ui/components/molecules/Price'

type Props = {
  rate: AvailabilityRate
  isOneNight: boolean
  howCouponApplies: 'both' | 'some' | undefined
  className?: string
}

export const CurrentRatePriceMobile: FC<Props> = ({
  rate,
  isOneNight,
  howCouponApplies,
  className,
}) => {
  const { marketPrice, calculateNightlyPricePreviousToDiscount } = useMarket()
  const { trans } = useTrans(['new-reservation'])
  const { promotionalCoupon } = useCoupons()

  const hasToShowPricePreviousToDiscountInThisMarket =
    marketPrice?.showPricePreviousToDiscount ?? false

  const hasDiscount =
    isDefined(rate.averageNightly.netDiscount) &&
    isDefined(rate.averageNightly.grossDiscount)

  return (
    <Flex
      alignItems="flex-end"
      direction="column"
      className={classNames('mb-xs', className)}
    >
      {isDefined(promotionalCoupon) && howCouponApplies === 'both' && (
        <CouponTag promotionalCoupon={promotionalCoupon} />
      )}
      <div>
        {isDefined(rate.relatedRate) &&
          (!hasToShowPricePreviousToDiscountInThisMarket || !hasDiscount) && (
            <StrikethroughPrice
              ariaLabel={trans('available-rooms_previous-price')}
              grossPrice={rate.relatedRate.rate.averageNightly.grossPrice}
              netPrice={rate.relatedRate.rate.averageNightly.netPrice}
            />
          )}
        {hasDiscount && hasToShowPricePreviousToDiscountInThisMarket && (
          <StrikethroughPrice
            ariaLabel={trans('available-rooms_previous-price')}
            grossPrice={calculateNightlyPricePreviousToDiscount(
              rate.averageNightly.grossPrice,
              rate.averageNightly.grossDiscount!,
            )}
            netPrice={calculateNightlyPricePreviousToDiscount(
              rate.averageNightly.netPrice,
              rate.averageNightly.netDiscount!,
            )}
          />
        )}
        <Price
          ariaLabel={trans('available-rooms_current-price')}
          fontStyle="l"
          color="dark"
          grossPrice={rate.averageNightly.grossPrice}
          netPrice={rate.averageNightly.netPrice}
          round="off"
        />
        <Text fontStyle="s-500" color="dark">
          {trans('available-rooms_price-per-night-short')}
        </Text>
      </div>

      {!isOneNight && (
        <Text fontStyle="xs-300" color="mid">
          <Translate
            i18nKey="new-reservation:available-rooms_total-stay"
            components={{
              price: (
                <Price
                  ariaLabel={trans('available-rooms_current-price')}
                  fontStyle="xs"
                  fontWeight="mid"
                  color="mid"
                  grossPrice={rate.total.grossPrice}
                  netPrice={rate.total.netPrice}
                  round="off"
                />
              ),
            }}
          />
        </Text>
      )}
      <TaxInformation />
    </Flex>
  )
}
