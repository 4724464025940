import { Skeleton, Text } from 'src/ui/components'
import containerStyles from './NearbyHotels.module.scss'
import { NearbyHotelCardSkeleton } from './NearbyHotelCard/NearByHotelCardSkeleton'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Flex } from 'src/ui/styles/objects/Flex'
import { FC } from 'react'
import { classNames } from 'src/ui/utils/classnames'
import { Media } from 'src/ui/styles/objects/Media'

interface Props {
  className?: string
}

export const NearbyHotelsSkeleton: FC<Props> = ({ className }) => {
  const { trans } = useTrans(['new-reservation'])

  return (
    <div
      className={classNames(className, 'w-full')}
      data-testid="nearby-hotels-skeleton"
    >
      <Flex direction="column" gap="xxs">
        <Text fontStyle="xs-500" uppercase>
          {trans(
            'availability-error_not-found_alternative-hotels_subtitle-revamped',
          )}
        </Text>
        <Skeleton width={180} height={24} />
      </Flex>
      <div className={containerStyles.hotels}>
        <Flex gap="l">
          <NearbyHotelCardSkeleton />
          <Media laptop desktop>
            <NearbyHotelCardSkeleton />
          </Media>
        </Flex>
      </div>
    </div>
  )
}
