import { FC } from 'react'

interface Props {
  color: string
}

export const DiscountIcon: FC<Props> = ({ color }) => (
  <>
    <g clipPath="url(#clip0_3600_88690)">
      <path
        d="M31.4997 15.9946L31.4996 15.9946L31.4997 16.0054C31.522 18.0696 30.1698 19.9312 28.1194 20.6104C27.9805 20.6564 27.8687 20.761 27.8135 20.8965C27.7584 21.0321 27.7654 21.185 27.8326 21.315C28.801 23.185 28.4546 25.4405 26.9564 26.9622L26.9562 26.9624C25.5 28.4433 23.1871 28.8046 21.3152 27.8337C21.1823 27.7648 21.0254 27.759 20.8878 27.8181C20.7502 27.8771 20.6463 27.9947 20.6047 28.1385C20.0325 30.1152 18.157 31.5 15.9998 31.5C13.8425 31.5 11.967 30.1152 11.3948 28.1385C11.3532 27.9947 11.2493 27.8771 11.1117 27.8181C10.9742 27.759 10.8174 27.7648 10.6844 27.8337C8.81188 28.8043 6.49862 28.4431 5.04165 26.9623L5.04163 26.9623C3.54391 25.4403 3.19809 23.1848 4.16682 21.3151C4.23374 21.1859 4.2411 21.034 4.18696 20.899C4.13283 20.764 4.02258 20.6592 3.88497 20.6121C1.84616 19.9133 0.5 18.0603 0.5 15.9992C0.5 13.9382 1.84616 12.0851 3.88497 11.3864C4.02258 11.3393 4.13283 11.2345 4.18696 11.0995C4.2411 10.9645 4.23374 10.8126 4.16682 10.6834C3.19809 8.81369 3.54391 6.55822 5.04163 5.0362C6.49896 3.55593 8.81214 3.19532 10.6844 4.1663C10.8173 4.23523 10.9741 4.24097 11.1117 4.18193C11.2493 4.12289 11.3532 4.00528 11.3948 3.86145C11.967 1.88479 13.8425 0.5 15.9998 0.5C18.157 0.5 20.0325 1.88479 20.6047 3.86145C20.6463 4.0053 20.7502 4.12292 20.8879 4.18195C21.0255 4.24098 21.1823 4.23522 21.3153 4.16624C23.1869 3.19503 25.4998 3.55572 26.9563 5.03616L26.9564 5.03629C28.4546 6.558 28.801 8.81351 27.8326 10.6835C27.7654 10.8134 27.7584 10.9663 27.8135 11.1019C27.8687 11.2374 27.9804 11.342 28.1193 11.388C30.17 12.0678 31.5222 13.9299 31.4997 15.9946Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8438 21.7298L22.3021 10.2715"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="11.9896"
        cy="11.4167"
        rx="2.29167"
        ry="2.29167"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="21.1563"
        cy="20.5827"
        rx="2.29167"
        ry="2.29167"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3600_88690">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </>
)
