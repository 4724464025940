import { FC } from 'react'
import styles from './NotLoggedUserContent.module.scss'
import { HeroBanner } from '../_components/HeroBanner'
import { Advantages } from '../_components/Advantages'
import { AllThisIsYours } from '../_components/AllThisIsYours'
import { UserLevelV2 } from 'src/core/User/domain/User'
import { Text } from 'src/ui/components'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Image } from 'src/ui/components/atoms/Image'
import { getPngServiceImageLoader } from 'src/ui/utils/images'
import { useMedia } from 'src/ui/hooks/useMedia'
import { useTrans } from 'src/ui/hooks/useTrans'

export const NotLoggedUserContent: FC = () => {
  const { media } = useMedia()
  const { trans } = useTrans(['myb-lite-landing'])
  const imageSlides = [
    {
      src: 'Carrusel0',
      alt: trans('lite_landing-hero_banner-carousel_alt-1'),
    },
    {
      src: 'Carrusel2',
      alt: trans('lite_landing-hero_banner-carousel_alt-2'),
    },
    {
      src: 'Carrusel3',
      alt: trans('lite_landing-hero_banner-carousel_alt-3'),
    },
    {
      src: 'Carrusel4',
      alt: trans('lite_landing-hero_banner-carousel_alt-4'),
    },
    {
      src: 'Carrusel5',
      alt: trans('lite_landing-hero_banner-carousel_alt-5'),
    },
  ]

  const steps = [
    {
      title: 'lite_landing-not_logged-steps-step1_title',
      content: 'lite_landing-not_logged-steps-step1_content',
    },
    {
      title: 'lite_landing-not_logged-steps-step2_title',
      content: 'lite_landing-not_logged-steps-step2_content',
      lightText: true,
    },
    {
      title: 'lite_landing-not_logged-steps-step3_title',
      content: 'lite_landing-not_logged-steps-step3_content',
      lightText: true,
    },
  ]

  return (
    <>
      <HeroBanner imageSlides={imageSlides} />
      <AllThisIsYours />
      <div className={styles.stepsContainer}>
        <Text
          as="h2"
          fontStyle={{
            mobile: '2xl-700',
            desktop: '3xl-700',
          }}
          centered
        >
          {trans('lite_landing-not_logged-steps-title')}
        </Text>
        <Flex
          direction="column"
          gap={media === 'mobile' ? 'l' : 'xl'}
          className={styles.steps}
        >
          {steps.map((step, index) => (
            <div key={index} className={styles[`step${index + 1}`]}>
              <Step
                stepNumberImage={(index + 1).toString()}
                title={trans(step.title)}
                description={trans(step.content)}
                lightText={step.lightText}
              />
            </div>
          ))}
        </Flex>
      </div>
      <Advantages
        userLevel={UserLevelV2.NotFound}
        title={trans('lite_landing-not_logged-advantages-title')}
      />
    </>
  )
}

const Step: FC<{
  stepNumberImage: string
  title: string
  description: string
  lightText?: boolean
}> = ({ stepNumberImage, title, description, lightText = false }) => {
  const { media } = useMedia()
  const { trans } = useTrans(['myb-lite-landing'])

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="center"
        className={styles.stepImageContainer}
      >
        <Image
          className={styles.stepImage}
          src={`Number0${stepNumberImage}`}
          loader={getPngServiceImageLoader}
          alt={trans('lite_landing-not_logged-steps-icon_number', {
            number: stepNumberImage,
          })}
          width={media === 'mobile' ? 152 : 294}
          height={media === 'mobile' ? 209 : 142}
        />
      </Flex>
      <div className={styles.stepContent}>
        <Text
          fontStyle={{ mobile: 'l-700', tablet: 'xl-700' }}
          as="h3"
          className={styles.stepTitle}
          color={lightText ? 'light' : 'dark'}
        >
          {title}
        </Text>
        <Text
          fontStyle={{ mobile: 'm-300', tablet: 'ml-300' }}
          as="p"
          className={styles.stepDescription}
          color={lightText ? 'light' : 'dark'}
        >
          {description}
        </Text>
      </div>
    </>
  )
}
